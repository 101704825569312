import { ICellEditorAngularComp, ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellEditorParams, ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';

export interface AgGridDropdownCellEditorParams<T = any> {
  options: T[];
  labelField?: keyof T & string;
  valueField?: keyof T & string;
}

type Params<T = any> = ICellEditorParams & AgGridDropdownCellEditorParams<T>;

@Component({
  selector: 'bl-ag-grid-dropdown-cell-editor',
  styles: [':host { width: 100% }'],
  template: `
    <p-dropdown
      appendTo="body"
      styleClass="w-full p-inputtext-sm"
      [options]="options"
      [optionLabel]="labelField"
      [optionValue]="valueField"
      [(ngModel)]="value"
    ></p-dropdown>
  `,
})
export class AgGridDropdownCellEditor<T = any> implements ICellEditorAngularComp {
  params!: Params;
  value!: any;
  options: T[];
  labelField: string;
  valueField: string;

  constructor() {}

  agInit(params: Params<T>): void {
    console.log('[DropdownCellEditor] agInit', params);
    this.params = params;
    this.value = this.params.value;
    this.options = this.params.options;
    this.labelField = this.params.labelField ?? 'label';
    this.valueField = this.params.valueField ?? 'value';
  }

  /* Component Editor Lifecycle methods */

  // the final value to send to the grid, on completion of editing
  getValue() {
    return this.value;
  }
}
