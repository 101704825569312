import { ICellRendererAngularComp } from 'ag-grid-angular';
import { CellStyle, ICellRendererParams } from 'ag-grid-community';
import { Component } from '@angular/core';

export interface ValorPontuacaoColumnParams {
  fieldIdPrecisao?: string;
  fieldVlPontuacaoPrevia?: string;
  fieldPcDeltaPrevia?: string;
}

export const ValorPontuacaoCellStyle: CellStyle = {
  flexDirection: 'column',
  alignItems: 'stretch',
  justifyContent: 'center',
  lineHeight: '17px',
  textAlign: 'center',
};

type Params = ICellRendererParams & ValorPontuacaoColumnParams;

@Component({
  selector: 'bl-valor-pontuacao-cell',
  template: `<bl-simulador-valores-pontuacao
    [vlPontuacao]="value"
    [idPrecisao]="idPrecisao"
    [vlPontuacaoPrevia]="vlPontuacaoPrevia"
    [pcDeltaPrevia]="pcDeltaPrevia"
  >
  </bl-simulador-valores-pontuacao>`,
})
export class ValorPontuacaoColumnRenderer implements ICellRendererAngularComp {
  params: Params;
  value: number;

  idPrecisao: number;
  vlPontuacaoPrevia: number;
  pcDeltaPrevia: number;

  constructor() {}

  // gets called once before the renderer is used
  agInit(params: Params): void {
    this.updateValue(params);
  }

  // gets called whenever the cell refreshes
  refresh(params: Params) {
    this.updateValue(params);
    return true;
  }

  updateValue(params: Params) {
    this.params = params;
    this.value = params.value;
    if (!params.data) {
      return;
    }
    if (params.fieldIdPrecisao) {
      this.idPrecisao = params.data[params.fieldIdPrecisao];
    }
    if (params.fieldVlPontuacaoPrevia) {
      this.vlPontuacaoPrevia = params.data[params.fieldVlPontuacaoPrevia];
    }
    if (params.fieldPcDeltaPrevia) {
      this.pcDeltaPrevia = params.data[params.fieldPcDeltaPrevia];
    }
  }
}
